import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_virtual_scroll = _resolveComponent("q-virtual-scroll")!

  return (_openBlock(), _createBlock(_component_q_virtual_scroll, {
    class: "tm-virtual-scroll",
    style: _normalizeStyle(_ctx.scrollStyles),
    items: _ctx.items
  }, {
    default: _withCtx(({ item, index }) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({ item, index })), undefined, true)
    ]),
    _: 3
  }, 8, ["style", "items"]))
}