
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
      default: 300,
    },
  },
  setup(props) {
    const scrollStyles = computed(() => {
      return `max-height: ${props.height}px;`
    })

    return {
      scrollStyles,
    }
  },
})
