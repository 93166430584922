<template>
  <div class="filter-empty">
    <tm-icon
      name="search_off"
      custom-size="32px"
      class="light--text mb-2"
    />
    <div class="filter-empty__title">There are no matching results</div>
  </div>
</template>

<style lang="scss" scoped>
.filter-empty {
  padding: 10px 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    text-align: center;
    color: $distinct;
    font-style: italic;
  }
}
</style>
