
import { defineComponent, onMounted } from 'vue'
import type { PropType } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownFilterButton from '@/components/shared/dropdowns/TmDropdownFilterButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import type { StatusType } from '@/definitions/shared/types'

export default defineComponent({
  name: 'DropdownFilterStatusesSingle',
  components: {
    TmDropdown,
    TmDropdownFilterButton,
    TmDropdownItem,
    TmStatus,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
    },
    modelValue: {
      type: Object as PropType<StatusType>,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    onMounted(() => {
      if (!props.modelValue && props.options?.length) {
        context.emit('update:modelValue', props.options[0])
      }
    })
  },
})
