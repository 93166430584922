
import { computed, defineComponent, ref } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

export default defineComponent({
  name: 'TmDropdownSelect',
  components: {
    TmDropdownItem,
    TmSearch,
    TmButton,
    TmDropdown,
  },
  props: {
    disable: {
      type: Boolean,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    dropdownIcon: {
      type: String,
      default: 'add',
    },
    dropdownLabel: {
      type: String,
      default: 'Add',
    },
    allSelectedText: {
      type: String,
      default: 'All added',
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    showSelectedOptions: {
      type: Boolean,
    },
    btnUnderOptions: {
      type: Boolean,
      default: false,
    },
    singleField: {
      type: Boolean,
    },
    optionsListClass: {
      type: String,
    },
    deleteButtonClass: {
      type: String,
    },
    hideMenu: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'add-click'],
  setup(props: {
    options: any,
    modelValue: any,
    showSelectedOptions: boolean
  }, context) {
    const search = ref('')

    const filteredOptions = computed(() => {
      if (props.showSelectedOptions) {
        return props.options
      }

      const uidsSelected = props.modelValue.map((el: any) => el.uid)
      const unselected = props.options?.filter((el: any) => !uidsSelected.includes(el.uid))

      if (search.value) {
        return unselected?.filter((el: any) => new RegExp(search.value, 'i').test(el.title))
      }
      return unselected
    })

    const updateSelectedItem = (option: any, newValue: any) => {
      context.emit('update:modelValue', props.modelValue.map((v: any) => v !== option ? v : newValue))
    }

    const selectOption = (option: any, close: () => void) => {
      context.emit('update:modelValue', [...props.modelValue, option])
      close()
    }
    const removeOption = (optionIndex: number) => {
      context.emit('update:modelValue', props.modelValue.filter((el: any, itemIndex: number) => itemIndex !== optionIndex))
    }

    return {
      search,
      filteredOptions,
      selectOption,
      removeOption,
      updateSelectedItem,
    }
  },
})
