import { assigneeOptions, chatStatusOptions, priorityOptions, ticketsStatusOptions } from '@/definitions/_general/_data/optionsList'

export type FilterItems = {
  uid: number;
  name: string;
  value: any;
  options?: any;
  component: string;
  searchable?: boolean;
  isDefault?: boolean;
  placeholder?: string;
  label?: string;
}

/*

import { assigneeOptions, chatStatusOptions } from '@/definitions/_general/_data/optionsList'
import type { FilterItems } from '@/definitions/shared/dropdowns/types'

 */

export const ChatStatusesFilter: FilterItems = {
  uid: 1,
  name: 'Status',
  value: null,
  options: chatStatusOptions,
  component: 'dropdown-filter-statuses-single',
  searchable: false,
}

export const TagsFilter: FilterItems = {
  uid: 2,
  name: 'Tags',
  value: [],
  component: 'dropdown-filter-autocomplete',
  options: ['payment', 'custom', 'update', 'test', 'vip'],
  placeholder: 'Enter tag name',
  label: 'Tags',
  searchable: false,
  isDefault: true,
}

export const AssigneeFilter: FilterItems = {
  uid: 3,
  name: 'Assignee',
  value: [],
  options: assigneeOptions,
  component: 'dropdown-filter-assignee',
  searchable: true,
}

export const ListsFilter: FilterItems = {
  uid: 4,
  name: 'Lists',
  value: [],
  component: 'dropdown-filter-autocomplete',
  options: ['VIP Leads', 'Marketing', 'Management', 'Leads', 'May 2023'],
  placeholder: 'Enter lists name',
  label: 'Lists',
  searchable: false,
}

export const ChannelFilter: FilterItems = {
  uid: 5,
  name: 'Channel',
  value: [],
  component: 'dropdown-filter-autocomplete',
  options: ['Mobile', 'Live'],
  placeholder: 'Enter channel name',
  label: 'Channel',
  searchable: false,
  isDefault: true,
}

export const PriorityFilter: FilterItems = {
  uid: 10,
  name: 'Priority',
  value: [],
  options: priorityOptions,
  component: 'dropdown-filter-priority',
  searchable: false,
}

export const TicketsStatusesFilter: FilterItems = {
  uid: 11,
  name: 'Status',
  value: null,
  options: ticketsStatusOptions,
  component: 'dropdown-filter-statuses-single',
  searchable: false,
}

export const RequesterFilter: FilterItems = {
  uid: 12,
  name: 'Requester',
  value: [],
  options: assigneeOptions,
  component: 'dropdown-filter-assignee',
  searchable: true,
}

export const LastUpdatedFilter: FilterItems = {
  uid: 13,
  name: 'Last updated',
  value: 'All',
  component: 'dropdown-filter-date',
}

export const CreatedFilter: FilterItems = {
  uid: 14,
  name: 'Created',
  value: 'All',
  component: 'dropdown-filter-date',
}
