
import type { PropType } from 'vue'
import { defineComponent, ref, computed } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import FilterButton from '@/components/shared/filters/FilterButton.vue'
import FilterPanelButton from '@/components/shared/filters/FilterPanelButton.vue'
import FilterAutocomplete from '@/components/shared/filters/FilterAutocomplete.vue'
import { capitalize } from '@/services/utils'

export default defineComponent({
  name: 'DropdownFilterAutocomplete',
  components: {
    FilterAutocomplete,
    FilterButton,
    FilterPanelButton,
    TmButton,
    TmDropdown,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    class: {
      type: String,
    },
    optionLabel: {
      type: String,
      default: '',
    },
    name: {
      type: String,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    panelView: {
      type: Boolean,
      default: false,
    },
    btnSize: {
      type: String as PropType<'default' | 'small' | 'large' | 'xLarge'>,
      default: 'large',
    },
  },
  emits: ['show', 'update:modelValue'],
  setup(props, context) {
    const valueStr = computed(() => {
      const getLabel = (item: any) => typeof item === 'string' ? capitalize(item) : capitalize(item[props.optionLabel])

      if (props.modelValue.length === props.options.length || props.modelValue.length === 0) {
        return 'All'
      }
      if (props.modelValue.length < 3) {
        return props.modelValue.reduce((acc, val, index) => {
          if (!index) return getLabel(val)
          return `${acc}, ${getLabel(val)}`
        }, '')
      }
      return `${getLabel(props.modelValue[0])}, ${getLabel(props.modelValue[1])}`
    })

    const valueStrPlus = computed(() => {
      if (props.modelValue.length === props.options.length || props.modelValue.length === 0) {
        return ''
      }
      return props.modelValue.length > 2 ? `+${props.modelValue.length - 2}` : ''
    })

    const filterAutocompleteEl = ref()
    const setFocus = () => {
      if (filterAutocompleteEl.value) {
        filterAutocompleteEl.value.focus()
      }
      context.emit('show')
    }

    return {
      wrapperClass: props.class,
      valueStr,
      valueStrPlus,
      filterAutocompleteEl,
      setFocus,
    }
  },
})
