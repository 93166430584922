
import { defineComponent } from 'vue'
import TmColorIndicator from '@/components/shared/TmColorIndicator.vue'
import { colorPaletteOptions } from '@/definitions/_general/_data/colorPaletteOptions'
import type { ColorPaletteType, CustomColorPaletteType } from '@/definitions/_general/_types/colorPalette'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmColorIndicator,
  },
  props: {
    modelValue: {
      type: Object,
    },
    items: {
      type: Array as PropType<ColorPaletteType[] | CustomColorPaletteType[]>,
      default: () => colorPaletteOptions,
    },
    size: {
      type: String,
      default: '32px',
    },
    gap: {
      type: String,
      default: '6px',
    },
    alignStart: {
      type: Boolean,
    },
    gradient: {
      type: Boolean,
      default: false,
    },
    allowNoColor: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'update:gradient'],
  setup() {
    const noColorOption = { customColor: 'unset' }

    return {
      noColorOption,
    }
  },
})
