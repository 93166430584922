
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import TmChip from '@/components/shared/TmChip.vue'
import { rgbToHex, styleVars } from '@/compositions/styleVariables'

export default defineComponent({
  components: { TmChip, TmButton, TmEllipsis },
  props: {
    value: {
      type: String,
    },
    valuePlus: {
      type: [String, Number],
    },
    name: {
      type: String,
    },
    icon: {
      type: String,
    },
    btnPreLabel: {
      type: String,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    isOpen: {
      type: Boolean,
    },
    allText: {
      type: String,
      default: 'All',
    },
    btnSize: {
      type: String as PropType<'' | 'small' | 'large'>,
      default: '',
    },
    buttonClass: {
      type: String,
    },
    valueClass: {
      type: String,
    },
  },
  emits: ['clear'],
  setup(props) {
    const status = computed(() => props.btnPreLabel || props.allText)
    const valuePlusColor = rgbToHex(styleVars.input)

    return {
      status,
      valuePlusColor,
    }
  },
})
