import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_status = _resolveComponent("tm-status")!

  return (_openBlock(), _createBlock(_component_tm_status, {
    gradient: _ctx.gradient,
    class: _normalizeClass(["tm-color-indicator", {
      'tm-color-indicator--hoverable': _ctx.hoverable,
      'tm-color-indicator--selected': _ctx.selected
    }]),
    static: !_ctx.hoverable,
    style: _normalizeStyle({
      fontSize: _ctx.size,
      width: _ctx.size,
      height: _ctx.size
    })
  }, null, 8, ["gradient", "static", "class", "style"]))
}