
import { defineComponent } from 'vue'
import TmStatus from '@/components/shared/TmStatus.vue'

export default defineComponent({
  components: {
    TmStatus,
  },
  props: {
    hoverable: {
      type: Boolean,
      default: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '28px',
    },
    gradient: {
      type: Boolean,
      default: false,
    },
  },
})
