import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44e8cde8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_autocomplete = _resolveComponent("filter-autocomplete")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, {
    class: _normalizeClass(["dropdown-filter-autocomplete", _ctx.wrapperClass]),
    "filter-dropdown": "",
    onShow: _ctx.setFocus
  }, {
    menu: _withCtx(({ close }) => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "filter", _normalizeProps(_guardReactiveProps({ close })), () => [
          _createVNode(_component_filter_autocomplete, _mergeProps({ ref: "filterAutocompleteEl" }, _ctx.$attrs, {
            "model-value": _ctx.modelValue,
            options: _ctx.options,
            "option-label": _ctx.optionLabel,
            onOnApply: close,
            onOnCancel: close,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
          }), {
            option: _withCtx(({ opt }) => [
              _renderSlot(_ctx.$slots, "option", _normalizeProps(_guardReactiveProps({ opt })), undefined, true)
            ]),
            "selected-item": _withCtx(({ opt }) => [
              _renderSlot(_ctx.$slots, "selected-item", _normalizeProps(_guardReactiveProps({ opt })), undefined, true)
            ]),
            _: 2
          }, 1040, ["model-value", "options", "option-label", "onOnApply", "onOnCancel"])
        ], true)
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.panelView ? 'filter-panel-button' : 'filter-button'), {
        class: "mr-2",
        removable: _ctx.removable,
        value: _ctx.valueStr,
        "value-plus": _ctx.valueStrPlus,
        name: _ctx.name,
        "btn-size": _ctx.btnSize
      }, null, 8, ["removable", "value", "value-plus", "name", "btn-size"]))
    ]),
    _: 3
  }, 8, ["class", "onShow"]))
}