import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "body-text-semi-bold-14 mr-2" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex align-center"
}
const _hoisted_3 = { class: "text-capitalize font-weight-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_dropdown_filter_button = _resolveComponent("tm-dropdown-filter-button")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, {
    class: "w100pr",
    fit: ""
  }, {
    menu: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, i) => {
        return (_openBlock(), _createBlock(_component_tm_dropdown_item, {
          key: i,
          onClick: ($event: any) => (_ctx.$emit('update:modelValue', item))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_status, {
              name: item.name,
              color: item.color,
              outline: item.outline
            }, null, 8, ["name", "color", "outline"])
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_dropdown_filter_button, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.name) + ": ", 1),
          (_ctx.modelValue?.name)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.modelValue?.name), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}