
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmColorPalette from '@/components/shared/TmColorPalette.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmDropdownFilter from '@/components/shared/dropdowns/TmDropdownFilter.vue'
import { colorPaletteOptions } from '@/definitions/_general/_data/colorPaletteOptions'
import type { ColorPaletteType } from '@/definitions/_general/_types/colorPalette'
import { TagsFilter, ChatStatusesFilter, ChannelFilter, AssigneeFilter, ListsFilter } from '@/definitions/shared/dropdowns/data'
import DropdownFilterStatusesSingle from '@/components/shared/filters/dropdown/DropdownFilterStatusesSingle.vue'
import DropdownFilterAutocomplete from '@/components/shared/filters/dropdown/DropdownFilterAutocomplete.vue'
import DropdownFilterAssignee from '@/components/shared/filters/dropdown/DropdownFilterAssignee.vue'

export default defineComponent({
  components: { TmButton, TmModal, TmColorPalette, TmFormLine, TmDropdownFilter, DropdownFilterStatusesSingle, DropdownFilterAssignee, DropdownFilterAutocomplete },
  setup() {
    const name = ref('My custom view 1')
    const colorPaletteItems = colorPaletteOptions.filter((e: ColorPaletteType) => !e.outline)
    const color = ref(colorPaletteItems[0])
    const filterItems = ref([
      ChatStatusesFilter,
      AssigneeFilter,
      TagsFilter,
      ListsFilter,
      ChannelFilter,
    ])
    const filters = ref(filterItems.value)

    return {
      filters,
      color,
      filterItems,
      colorPaletteItems,
      name,
    }
  },
})
