import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cca8c702"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lh-20" }
const _hoisted_2 = { class: "w-276px pb-2" }
const _hoisted_3 = { class: "overflow-hidden w100pr text-ellipsis" }
const _hoisted_4 = {
  key: 1,
  class: "d-flex align-center text-grey px-4"
}
const _hoisted_5 = { class: "d-flex w100pr align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_search = _resolveComponent("tm-search")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-dropdown-filter", {
      'd-flex flex-column-reverse': _ctx.btnUnderOptions,
    }])
  }, [
    _createVNode(_component_tm_dropdown, {
      class: "d-flex flex-column align-start",
      "close-on-content-click": false
    }, _createSlots({
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          (!_ctx.singleField)
            ? (_openBlock(), _createBlock(_component_tm_button, {
                key: 0,
                class: _normalizeClass(["tm-dropdown-filter__btn-dropdown", {
            'mt-3': _ctx.btnUnderOptions && _ctx.modelValue.length,
            'tm-dropdown-filter__btn-dropdown--disable': _ctx.disable,
          }]),
                "text-link": "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add-click')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_tm_icon, {
                    name: _ctx.dropdownIcon,
                    size: "small",
                    left: ""
                  }, null, 8, ["name"]),
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.dropdownLabel), 1)
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true)
        ], true)
      ]),
      _: 2
    }, [
      (!_ctx.hideMenu)
        ? {
            name: "menu",
            fn: _withCtx(({ close }) => [
              _createVNode(_component_tm_search, {
                modelValue: _ctx.search,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
                class: "w-276px px-4 pt-3 pb-2",
                size: "small",
                placeholder: "Search"
              }, null, 8, ["modelValue"]),
              _createElementVNode("div", _hoisted_2, [
                (_ctx.filteredOptions.length)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.filteredOptions, (option) => {
                      return (_openBlock(), _createBlock(_component_tm_dropdown_item, {
                        key: option.uid,
                        onClick: ($event: any) => (_ctx.selectOption(option, close))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_3, [
                            _renderSlot(_ctx.$slots, "dropdown-item", { opt: option }, () => [
                              _createTextVNode(_toDisplayString(option.text), 1)
                            ], true)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.options.length !== _ctx.modelValue.length ? 'No results' : _ctx.allSelectedText), 1))
              ])
            ])
          }
        : undefined
    ]), 1024),
    _createElementVNode("div", {
      class: _normalizeClass({ 'mt-3': !_ctx.btnUnderOptions && _ctx.modelValue.length })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (option, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: option.uid,
          class: "selected-position"
        }, [
          (_ctx.$slots['selected-item-prepend'])
            ? _renderSlot(_ctx.$slots, "selected-item-prepend", {
                key: 0,
                index: i
              }, undefined, true)
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: _normalizeClass(["d-flex flex-grow-1 overflow-hidden align-center border-radius", _ctx.optionsListClass])
            }, [
              _renderSlot(_ctx.$slots, "selected-item", {
                opt: option,
                index: i,
                updateSelectedItem: _ctx.updateSelectedItem
              }, undefined, true)
            ], 2),
            _createVNode(_component_tm_button, {
              class: _normalizeClass(["tm-dropdown-filter__btn", {
              [`${_ctx.deleteButtonClass}`]: _ctx.deleteButtonClass,
              'tm-dropdown-filter__btn--disable': _ctx.disable,
              'tm-dropdown-filter__btn--hidden': _ctx.singleField || option.isDefault
            }]),
              "icon-only": "",
              size: "xLarge",
              flat: "",
              color: "transparent",
              onClick: ($event: any) => (_ctx.removeOption(i))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_tm_icon, {
                  name: "delete",
                  class: "distinct--text",
                  size: "xLarge"
                })
              ]),
              _: 2
            }, 1032, ["class", "onClick"])
          ])
        ]))
      }), 128))
    ], 2)
  ], 2))
}