import type { ColorPaletteType } from '@/definitions/_general/_types/colorPalette'

export const colorPaletteOptions: ColorPaletteType[] = [
  {
    color: 'blue',
    varName: 'blue500',
    outline: false,
  },
  {
    color: 'orange',
    varName: 'orange500',
    outline: false,
  },
  {
    color: 'green',
    varName: 'green500',
    outline: false,
  },
  {
    color: 'red',
    varName: 'red500',
    outline: false,
  },
  {
    color: 'cyan',
    varName: 'mint500',
    outline: false,
  },
  {
    color: 'violet',
    varName: 'purple500',
    outline: false,
  },
  {
    color: 'purpur',
    varName: 'crimson500',
    outline: false,
  },
  {
    color: 'brown',
    varName: 'brown500',
    outline: false,
  },
  {
    color: 'blue',
    varName: 'blue500',
    outline: true,
  },
  {
    color: 'orange',
    varName: 'orange500',
    outline: true,
  },
  {
    color: 'green',
    varName: 'green500',
    outline: true,
  },
  {
    color: 'red',
    varName: 'red500',
    outline: true,
  },
  {
    varName: 'mint500',
    color: 'cyan',
    outline: true,
  },
  {
    color: 'violet',
    varName: 'purple500',
    outline: true,
  },
  {
    color: 'purpur',
    varName: 'crimson500',
    outline: true,
  },
  {
    color: 'brown',
    varName: 'brown500',
    outline: true,
  },
]

export const colorPaletteOptionsOutline = colorPaletteOptions.filter((el: ColorPaletteType) => el.outline)
export const colorPaletteOptionsFilled = colorPaletteOptions.filter((el: ColorPaletteType) => !el.outline)
