import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2aa70364"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "emphasize--text"
}
const _hoisted_2 = { class: "d-flex align-center mx-1 lh-20 font-weight-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_ellipsis = _resolveComponent("tm-ellipsis")!
  const _component_tm_chip = _resolveComponent("tm-chip")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createBlock(_component_tm_button, {
    size: _ctx.btnSize,
    rounded: "",
    class: "filter-button"
  }, {
    default: _withCtx(() => [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_tm_icon, {
            key: 0,
            name: _ctx.icon,
            class: "filter-button__icon"
          }, null, 8, ["name"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.name) + ":", 1)),
      _createElementVNode("span", _hoisted_2, [
        _createElementVNode("span", {
          class: _normalizeClass(["filter-button-wrap", _ctx.buttonClass])
        }, [
          (!_ctx.value || _ctx.btnPreLabel)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(["filter-button__status", {'filter-button__status--negative': _ctx.btnPreLabel}])
              }, [
                _createVNode(_component_tm_ellipsis, { inline: "" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.status), 1)
                  ]),
                  _: 1
                })
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.value)
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: _normalizeClass(["flex-grow-0", _ctx.valueClass])
              }, [
                _createVNode(_component_tm_ellipsis, { inline: "" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.value), 1)
                  ]),
                  _: 1
                })
              ], 2))
            : _createCommentVNode("", true)
        ], 2),
        (_ctx.valuePlus)
          ? (_openBlock(), _createBlock(_component_tm_chip, {
              key: 0,
              class: "filter-button__chip",
              color: _ctx.valuePlusColor
            }, {
              default: _withCtx(() => [
                _createTextVNode("+" + _toDisplayString(_ctx.valuePlus), 1)
              ]),
              _: 1
            }, 8, ["color"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.removable)
        ? (_openBlock(), _createBlock(_component_tm_icon, {
            key: 2,
            name: "cancel",
            size: "xSmall",
            class: "filter-button__cancel-icon",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('clear')), ["stop","prevent"]))
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_tm_icon, {
        name: _ctx.isOpen ? 'tmi-arrow-drop-up-sharp' : 'tmi-arrow-drop-down-sharp',
        class: "filter-button__arrow-icon"
      }, null, 8, ["name"])
    ]),
    _: 1
  }, 8, ["size"]))
}