import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-405fd4f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-color-palette" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_color_indicator = _resolveComponent("tm-color-indicator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["tm-color-palette-list", { 'tm-color-palette-list--align-start': _ctx.alignStart }]),
      style: _normalizeStyle({
        'gap': _ctx.gap,
      })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (status, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "tm-color-palette__item",
          style: _normalizeStyle({
          'font-size': _ctx.size,
        })
        }, [
          _createVNode(_component_tm_color_indicator, {
            gradient: _ctx.gradient,
            color: status.color,
            hoverable: "",
            "custom-color": status.customColor,
            outline: status.outline,
            selected: (status.customColor && status.customColor === _ctx.modelValue?.customColor) || (!status.customColor && status.outline === _ctx.modelValue?.outline && status.color === _ctx.modelValue?.color),
            clickable: "",
            size: _ctx.size,
            onClick: ($event: any) => (_ctx.$emit('update:modelValue', status))
          }, null, 8, ["gradient", "color", "custom-color", "outline", "selected", "size", "onClick"])
        ], 4))
      }), 128)),
      (_ctx.allowNoColor)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["tm-color-palette__item", {
          'tm-color-palette__item--selected': _ctx.modelValue.customColor === _ctx.noColorOption.customColor,
        }]),
            style: _normalizeStyle({
          'font-size': _ctx.size,
        }),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.noColorOption)))
          }, [
            _createElementVNode("div", {
              class: "tm-color-palette__no-color",
              style: _normalizeStyle({
            'font-size': _ctx.size,
          })
            }, null, 4)
          ], 6))
        : _createCommentVNode("", true)
    ], 6)
  ]))
}